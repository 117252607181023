<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.name">
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-normal text-xs text-dark-blue">
        {{ activeUserInfo.name }}
      </p>
    </div>
    <div class="pp-seperator hidden sm:block"></div>
    <div class="show-in-large-screen hidden md:block flex justify-center">
      <div @click="activeUserInfo.type === 'user' ? $router.push({ name: 'admin-view-profile' }) : ''" class="mb-1">
        <S3ImageGenerator
          v-if="activeUserInfo.photoURL && activeUserInfo.photoURL != '/img/default.37f922ae.png'"
          :is-base64="false"
          :document="activeUserInfo.profileImage ? activeUserInfo.profileImage : activeUserInfo.profileImage"
          :customClass="'profile-image-circle-nav'"
          :key="activeUserInfo.profileImage ? activeUserInfo.profileImage : activeUserInfo.profileImage"
          class="rounded-full shadow-md block cursor-pointer"
        />
        <vs-avatar v-else class="font-medium text-base alpha-avatar cursor-pointer rounded-full shadow-md bg-white m-0" :text="generateFullName()"/>
      </div>
      <div class="text-center cursor-pointer leading-none" @click="logout">
        <span class="text-body-gray underline text-xxs whitespace-nowrap">Log out</span>
      </div>
    </div>
    <vs-dropdown class="cursor-pointer md:hidden" vs-trigger-click>
      <div class="con-img">
        <S3ImageGenerator
          v-if="activeUserInfo.photoURL && activeUserInfo.photoURL != '/img/default.37f922ae.png'"
          :is-base64="false"
          :document="activeUserInfo.profileImage ? activeUserInfo.profileImage : activeUserInfo.profileImage"
          :customClass="'profile-image-circle-nav'"
          :key="activeUserInfo.profileImage ? activeUserInfo.profileImage : activeUserInfo.profileImage"
          class="rounded-full shadow-md block cursor-pointer"
        />
        <vs-avatar v-else class="font-medium text-base alpha-avatar cursor-pointer rounded-full shadow-md bg-white m-0" :text="generateFullName()"/>
      </div>
      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li v-if="activeUserInfo.type === 'user'"class="flex py-2 px-4 w-full cursor-pointer hover:bg-primary hover:text-white" @click="$router.push({ name: 'admin-view-profile' })">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>
          <li v-if="activeUserInfo.type === 'user'" class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="$router.push({ name: 'admin-change-password' })">
            <feather-icon icon="KeyIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Change Password</span>
          </li>
          <li class="flex w-full py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import S3ImageGenerator from "../../../../views/components/S3ImageGenerator";
import { clearLocalStorage } from "../../../../helper/localStorage.js";
import { EventBus } from "../../../../mixins/event-bus";
import { mapActions } from "vuex";

export default {
  components: {
    S3ImageGenerator,
  },

  data() {
    return {
      leavePopUpAction: "default",
    };
  },

  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },

  methods: {
    ...mapActions("admin", ["logoutAdmin"]),
      removeToken() {
      if ( localStorage.getItem("accessToken")
        && typeof localStorage.getItem("redirectTo") === "string"
          && ( localStorage.getItem("redirectTo") === "undefined" ||
          localStorage.getItem("redirectTo") === "introducer-login"
        )
      ) {
        $cookies.remove("rememberMe");
        $cookies.remove("rememberMeToken");
        $cookies.remove("password");
        clearLocalStorage();
      }
    },
    generateFullName(fullName) {
      if (!"name" in this.activeUserInfo || !this.activeUserInfo.name) {
        return "";
      }

      return this.activeUserInfo.name
        .split(" ")
        .map((item) => item.charAt(0))
        .join("")
        .toUpperCase();
    },

    logout() {
      this.leavePopUpAction = "default";

      if (localStorage.getItem("formFieldUpdated") && localStorage.getItem("formFieldUpdated") === 'no') {
        this.removeToken();
        this.$router.push("/login").catch(() => {});
      } else {
        this.$router.push("/login").catch(() => {});
      }
    },
  },

  created() {
    this.leavePopUpAction = "default";
    EventBus.$on("leavePopUpTrigger", action => {
      this.leavePopUpAction = action;
    });
  },

  watch: {
    leavePopUpAction: {
      async handler(updatedValue, previousValue) {
        if (updatedValue && updatedValue === 'yes') {
          this.removeToken();
        }
      },
      flush: "post",
      immediate: true
    }
  }
};
</script>
