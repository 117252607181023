<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <footer class="the-footer flex-wrap justify-between text-base" :class="classes">
      <span class="text-base">
        &copy; {{ `${appName ==='FlipPay'  ? 'Flip Pay' : 'Rello Pay'} ${new Date().getFullYear()}`}}
      </span>
  </footer>
</template>

<script>

export default {
  data(){
    return{
      appName: process.env.VUE_APP_NAME ||"FlipPay",
    }
  },
  name: "the-footer",
  props: {
    classes: {
      type: String,
    },
  },
};
</script>
